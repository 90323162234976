// eslint-disable-next-line @typescript-eslint/no-explicit-any

var camelizeRE = /-(\w)/g;
export var camelize = function camelize(str) {
  return str.replace(camelizeRE, function (_, c) {
    return c ? c.toUpperCase() : '';
  });
};
var hyphenateRE = /\B([A-Z])/g;
export var hyphenate = function hyphenate(str) {
  return str.replace(hyphenateRE, '-$1').toLowerCase();
};
export function setInitialProps(propsList) {
  var res = {};
  propsList.forEach(function (key) {
    res[key] = undefined;
  });
  return res;
}
export function createCustomEvent(name, args) {
  return new CustomEvent(name, {
    bubbles: false,
    cancelable: false,
    detail: args.length === 1 ? args[0] : args
  });
}
var isBoolean = function isBoolean(val) {
  return /function Boolean/.test(String(val));
};
var isNumber = function isNumber(val) {
  return /function Number/.test(String(val));
};
export function convertAttributeValue(value, name) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    type = _ref.type;
  if (isBoolean(type)) {
    if (value === 'true' || value === 'false') {
      return value === 'true';
    }
    if (value === '' || value === name) {
      return true;
    }
    return value != null;
  } else if (isNumber(type)) {
    var parsed = parseFloat(value);
    return isNaN(parsed) ? value : parsed;
  } else {
    return value;
  }
}
export function toVNodes(children,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
h) {
  var res = [];
  for (var i = 0, l = children.length; i < l; i++) {
    res.push(toVNode(children[i], h));
  }
  return res;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toVNode(node, h) {
  if (node.nodeType === 3) {
    return node.data.trim() ? node.data : null;
  } else if (node.nodeType === 1) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var _data = {
      attrs: getAttributes(node),
      domProps: {
        innerHTML: node.innerHTML
      }
    };
    if (_data.attrs.slot) {
      _data.slot = _data.attrs.slot;
      delete _data.attrs.slot;
    }
    return h(node.tagName, _data);
  } else {
    return null;
  }
}
function getAttributes(node) {
  var res = {};
  for (var i = 0, l = node.attributes.length; i < l; i++) {
    var attr = node.attributes[i];
    res[attr.nodeName] = attr.nodeValue;
  }
  return res;
}