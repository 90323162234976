// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/* parser generated by jison 0.4.17 */
/*
  Returns a Parser object of the following structure:

  Parser: {
    yy: {}
  }

  Parser.prototype: {
    yy: {},
    trace: function(),
    symbols_: {associative list: name ==> number},
    terminals_: {associative list: number ==> name},
    productions_: [...],
    performAction: function anonymous(yytext, yyleng, yylineno, yy, yystate, $$, _$),
    table: [...],
    defaultActions: {...},
    parseError: function(str, hash),
    parse: function(input),

    lexer: {
        EOF: 1,
        parseError: function(str, hash),
        setInput: function(input),
        input: function(),
        unput: function(str),
        more: function(),
        less: function(n),
        pastInput: function(),
        upcomingInput: function(),
        showPosition: function(),
        test_match: function(regex_match_array, rule_index),
        next: function(),
        lex: function(),
        begin: function(condition),
        popState: function(),
        _currentRules: function(),
        topState: function(),
        pushState: function(condition),

        options: {
            ranges: boolean           (optional: true ==> token location info will include a .range[] member)
            flex: boolean             (optional: true ==> flex-like lexing behaviour where the rules are tested exhaustively to find the longest match)
            backtrack_lexer: boolean  (optional: true ==> lexer regexes are tested in order and for each matching regex the action code is invoked; the lexer terminates the scan when a token is returned by the action code)
        },

        performAction: function(yy, yy_, $avoiding_name_collisions, YY_START),
        rules: [...],
        conditions: {associative list: name ==> set},
    }
  }


  token location info (@$, _$, etc.): {
    first_line: n,
    last_line: n,
    first_column: n,
    last_column: n,
    range: [start_number, end_number]       (where the numbers are indexes into the input string, regular zero-based)
  }


  the parseError function receives a 'hash' object with these members for lexer and parser errors: {
    text:        (matched text)
    token:       (the produced terminal token, if any)
    line:        (yylineno)
  }
  while parser (grammar) errors will also provide these members, i.e. parser errors deliver a superset of attributes: {
    loc:         (yylloc)
    expected:    (string describing the set of expected tokens)
    recoverable: (boolean: TRUE when the parser has a error recovery rule available for this particular error)
  }
*/
import SqlParseSupport from "./sqlParseSupport";
var globalSearchParser = function () {
  var o = function o(k, v, _o, l) {
      for (_o = _o || {}, l = k.length; l--; _o[k[l]] = v);
      return _o;
    },
    $V0 = [6, 15, 16, 17, 20],
    $V1 = [2, 27],
    $V2 = [1, 3],
    $V3 = [1, 13],
    $V4 = [1, 16],
    $V5 = [1, 14],
    $V6 = [1, 18],
    $V7 = [2, 28],
    $V8 = [6, 9],
    $V9 = [2, 17],
    $Va = [6, 9, 16],
    $Vb = [1, 27],
    $Vc = [1, 26],
    $Vd = [1, 33],
    $Ve = [1, 34],
    $Vf = [1, 35],
    $Vg = [1, 41],
    $Vh = [2, 14];
  var parser = {
    trace: function trace() {},
    yy: {},
    symbols_: {
      "error": 2,
      "GlobalSearchAutocomplete": 3,
      "OptionalWhitespace": 4,
      "SearchParts": 5,
      "EOF": 6,
      "SearchParts_EDIT": 7,
      "SearchPart": 8,
      "WS": 9,
      "SearchPart_EDIT": 10,
      "Facet": 11,
      "FreeText": 12,
      "Facet_EDIT": 13,
      "FreeText_EDIT": 14,
      "FACET": 15,
      "CURSOR": 16,
      "TEXT": 17,
      "QuotedValue": 18,
      "QuotedValue_EDIT": 19,
      "QUOTE": 20,
      "VALUE": 21,
      "PARTIAL_VALUE": 22,
      "$accept": 0,
      "$end": 1
    },
    terminals_: {
      2: "error",
      6: "EOF",
      9: "WS",
      15: "FACET",
      16: "CURSOR",
      17: "TEXT",
      20: "QUOTE",
      21: "VALUE",
      22: "PARTIAL_VALUE"
    },
    productions_: [0, [3, 4], [3, 3], [3, 2], [5, 1], [5, 3], [7, 1], [7, 3], [7, 3], [7, 5], [8, 1], [8, 1], [10, 1], [10, 1], [11, 3], [13, 3], [13, 4], [12, 1], [12, 1], [14, 1], [14, 2], [14, 3], [14, 2], [14, 1], [18, 3], [18, 2], [19, 2], [4, 0], [4, 1]],
    performAction: function anonymous(yytext, yyleng, yylineno, yy, yystate /* action[1] */, $$ /* vstack */, _$ /* lstack */) {
      /* this == yyval */

      var $0 = $$.length - 1;
      switch (yystate) {
        case 1:
          return $$[$0 - 2];
          break;
        case 2:
          if (!$$[$0 - 1].facets) {
            $$[$0 - 1].facets = {};
          }
          if (!$$[$0 - 1].text) {
            $$[$0 - 1].text = [];
          }
          return $$[$0 - 1];
          break;
        case 3:
          return {
            facets: {},
            text: []
          };
          break;
        case 5:
          parser.mergeFacets($$[$0 - 2], $$[$0]);
          parser.mergeText($$[$0 - 2], $$[$0]);
          break;
        case 7:
          parser.mergeFacets($$[$0 - 2], $$[$0]);
          parser.mergeText($$[$0 - 2], $$[$0]);
          this.$ = $$[$0];
          this.$.text = $$[$0 - 2].text;
          this.$.facets = $$[$0 - 2].facets;
          break;
        case 8:
          this.$ = $$[$0 - 2];
          parser.mergeFacets(this.$, $$[$0]);
          parser.mergeText(this.$, $$[$0]);
          break;
        case 9:
          parser.mergeFacets($$[$0 - 4], $$[$0 - 2]);
          parser.mergeFacets($$[$0 - 4], $$[$0]);
          parser.mergeText($$[$0 - 4], $$[$0 - 2]);
          parser.mergeText($$[$0 - 4], $$[$0]);
          this.$ = $$[$0 - 2];
          this.$.text = $$[$0 - 4].text;
          this.$.facets = $$[$0 - 4].facets;
          break;
        case 10:
          this.$ = {
            text: [],
            facets: $$[$0].facets
          };
          break;
        case 11:
          this.$ = {
            text: [$$[$0]],
            facets: {}
          };
          break;
        case 14:
          var facet = {};
          var facetName = $$[$0 - 2].substring(0, $$[$0 - 2].length - 1).toLowerCase();
          facet[facetName] = {};
          facet[facetName][$$[$0].toLowerCase()] = true;
          this.$ = {
            facets: facet
          };
          break;
        case 15:
          this.$ = {
            suggestFacetValues: $$[$0 - 2].substring(0, $$[$0 - 2].length - 1).toLowerCase()
          };
          break;
        case 16:
          var facet = {};
          var facetName = $$[$0 - 3].substring(0, $$[$0 - 3].length - 1).toLowerCase();
          facet[facetName] = {};
          facet[facetName][$$[$0 - 1].toLowerCase()] = true;
          this.$ = {
            suggestFacetValues: facetName,
            facets: facet
          };
          break;
        case 19:
          this.$ = {
            suggestFacets: true,
            suggestResults: true
          };
          break;
        case 20:
          this.$ = {
            suggestFacets: true,
            suggestResults: true,
            text: [$$[$0]]
          };
          break;
        case 21:
          this.$ = {
            suggestFacets: true,
            suggestResults: true,
            text: [$$[$0 - 2] + $$[$0]]
          };
          break;
        case 22:
          this.$ = {
            suggestFacets: true,
            suggestResults: true,
            text: [$$[$0 - 1]]
          };
          break;
        case 24:
          this.$ = $$[$0 - 1];
          break;
        case 25:
          this.$ = '';
          break;
        case 26:
          this.$ = $$[$0];
          break;
      }
    },
    table: [o($V0, $V1, {
      3: 1,
      4: 2,
      9: $V2
    }), {
      1: [3]
    }, {
      5: 4,
      6: [1, 6],
      7: 5,
      8: 7,
      10: 8,
      11: 9,
      12: 10,
      13: 11,
      14: 12,
      15: $V3,
      16: $V4,
      17: $V5,
      18: 15,
      19: 17,
      20: $V6
    }, o($V0, $V7), {
      4: 19,
      6: $V1,
      9: [1, 20]
    }, {
      6: [1, 21]
    }, {
      1: [2, 3]
    }, o($V8, [2, 4]), {
      6: [2, 6],
      9: [1, 22]
    }, o($V8, [2, 10]), o($V8, [2, 11]), o($V8, [2, 12]), o($V8, [2, 13]), o([16, 17, 20], $V1, {
      4: 23,
      9: $V2
    }), o($V8, $V9, {
      16: [1, 24]
    }), o($Va, [2, 18]), o($V8, [2, 19], {
      17: [1, 25]
    }), o($V8, [2, 23]), {
      20: $Vb,
      21: $Vc,
      22: [1, 28]
    }, {
      6: [1, 29]
    }, {
      6: $V7,
      8: 30,
      10: 31,
      11: 9,
      12: 10,
      13: 11,
      14: 12,
      15: $V3,
      16: $V4,
      17: $V5,
      18: 15,
      19: 17,
      20: $V6
    }, {
      1: [2, 2]
    }, {
      5: 32,
      8: 7,
      11: 9,
      12: 10,
      15: $Vd,
      17: $Ve,
      18: 15,
      20: $Vf
    }, {
      12: 36,
      16: [1, 37],
      17: $Ve,
      18: 15,
      20: $Vf
    }, o($V8, [2, 22], {
      17: [1, 38]
    }), o($V8, [2, 20]), {
      20: [1, 39]
    }, o($Va, [2, 25]), o($V8, [2, 26]), {
      1: [2, 1]
    }, o($V8, [2, 5]), {
      6: [2, 7],
      9: [1, 40]
    }, {
      6: [2, 8],
      9: $Vg
    }, o([17, 20], $V1, {
      4: 42,
      9: $V2
    }), o($Va, $V9), {
      20: $Vb,
      21: $Vc
    }, o($V8, $Vh, {
      16: [1, 43]
    }), o($V8, [2, 15]), o($V8, [2, 21]), o($Va, [2, 24]), {
      5: 44,
      8: 7,
      11: 9,
      12: 10,
      15: $Vd,
      17: $Ve,
      18: 15,
      20: $Vf
    }, {
      8: 30,
      11: 9,
      12: 10,
      15: $Vd,
      17: $Ve,
      18: 15,
      20: $Vf
    }, {
      12: 45,
      17: $Ve,
      18: 15,
      20: $Vf
    }, o($V8, [2, 16]), {
      6: [2, 9],
      9: $Vg
    }, o($V8, $Vh)],
    defaultActions: {
      6: [2, 3],
      21: [2, 2],
      29: [2, 1]
    },
    parseError: function parseError(str, hash) {
      if (hash.recoverable) {
        this.trace(str);
      } else {
        var error = new Error(str);
        error.hash = hash;
        throw error;
      }
    },
    parse: function parse(input) {
      var self = this,
        stack = [0],
        tstack = [],
        vstack = [null],
        lstack = [],
        table = this.table,
        yytext = '',
        yylineno = 0,
        yyleng = 0,
        recovering = 0,
        TERROR = 2,
        EOF = 1;
      var args = lstack.slice.call(arguments, 1);
      var lexer = Object.create(this.lexer);
      var sharedState = {
        yy: {}
      };
      for (var k in this.yy) {
        if (Object.prototype.hasOwnProperty.call(this.yy, k)) {
          sharedState.yy[k] = this.yy[k];
        }
      }
      lexer.setInput(input, sharedState.yy);
      sharedState.yy.lexer = lexer;
      sharedState.yy.parser = this;
      if (typeof lexer.yylloc == 'undefined') {
        lexer.yylloc = {};
      }
      var yyloc = lexer.yylloc;
      lstack.push(yyloc);
      var ranges = lexer.options && lexer.options.ranges;
      if (typeof sharedState.yy.parseError === 'function') {
        this.parseError = sharedState.yy.parseError;
      } else {
        this.parseError = Object.getPrototypeOf(this).parseError;
      }
      function popStack(n) {
        stack.length = stack.length - 2 * n;
        vstack.length = vstack.length - n;
        lstack.length = lstack.length - n;
      }
      _token_stack: var lex = function lex() {
        var token;
        token = lexer.lex() || EOF;
        if (typeof token !== 'number') {
          token = self.symbols_[token] || token;
        }
        return token;
      };
      var symbol,
        preErrorSymbol,
        state,
        action,
        a,
        r,
        yyval = {},
        p,
        len,
        newState,
        expected;
      while (true) {
        state = stack[stack.length - 1];
        if (this.defaultActions[state]) {
          action = this.defaultActions[state];
        } else {
          if (symbol === null || typeof symbol == 'undefined') {
            symbol = lex();
          }
          action = table[state] && table[state][symbol];
        }
        if (typeof action === 'undefined' || !action.length || !action[0]) {
          var errStr = '';
          expected = [];
          for (p in table[state]) {
            if (this.terminals_[p] && p > TERROR) {
              expected.push('\'' + this.terminals_[p] + '\'');
            }
          }
          if (lexer.showPosition) {
            errStr = 'Parse error on line ' + (yylineno + 1) + ':\n' + lexer.showPosition() + '\nExpecting ' + expected.join(', ') + ', got \'' + (this.terminals_[symbol] || symbol) + '\'';
          } else {
            errStr = 'Parse error on line ' + (yylineno + 1) + ': Unexpected ' + (symbol == EOF ? 'end of input' : '\'' + (this.terminals_[symbol] || symbol) + '\'');
          }
          this.parseError(errStr, {
            text: lexer.match,
            token: this.terminals_[symbol] || symbol,
            line: lexer.yylineno,
            loc: lexer.yylloc,
            ruleId: stack.slice(stack.length - 2, stack.length).join('_'),
            expected: expected
          });
        }
        if (action[0] instanceof Array && action.length > 1) {
          throw new Error('Parse Error: multiple actions possible at state: ' + state + ', token: ' + symbol);
        }
        switch (action[0]) {
          case 1:
            stack.push(symbol);
            vstack.push(lexer.yytext);
            lstack.push(lexer.yylloc);
            stack.push(action[1]);
            symbol = null;
            if (!preErrorSymbol) {
              yyleng = lexer.yyleng;
              yytext = lexer.yytext;
              yylineno = lexer.yylineno;
              yyloc = lexer.yylloc;
              if (recovering > 0) {
                recovering--;
              }
            } else {
              symbol = preErrorSymbol;
              preErrorSymbol = null;
            }
            break;
          case 2:
            len = this.productions_[action[1]][1];
            yyval.$ = vstack[vstack.length - len];
            yyval._$ = {
              first_line: lstack[lstack.length - (len || 1)].first_line,
              last_line: lstack[lstack.length - 1].last_line,
              first_column: lstack[lstack.length - (len || 1)].first_column,
              last_column: lstack[lstack.length - 1].last_column
            };
            if (ranges) {
              yyval._$.range = [lstack[lstack.length - (len || 1)].range[0], lstack[lstack.length - 1].range[1]];
            }
            r = this.performAction.apply(yyval, [yytext, yyleng, yylineno, sharedState.yy, action[1], vstack, lstack].concat(args));
            if (typeof r !== 'undefined') {
              return r;
            }
            if (len) {
              stack = stack.slice(0, -1 * len * 2);
              vstack = vstack.slice(0, -1 * len);
              lstack = lstack.slice(0, -1 * len);
            }
            stack.push(this.productions_[action[1]][0]);
            vstack.push(yyval.$);
            lstack.push(yyval._$);
            newState = table[stack[stack.length - 2]][stack[stack.length - 1]];
            stack.push(newState);
            break;
          case 3:
            return true;
        }
      }
      return true;
    }
  };
  SqlParseSupport.initGlobalSearchParser(parser); /* generated by jison-lex 0.3.4 */
  var lexer = function () {
    var lexer = {
      EOF: 1,
      parseError: function parseError(str, hash) {
        if (this.yy.parser) {
          this.yy.parser.parseError(str, hash);
        } else {
          throw new Error(str);
        }
      },
      // resets the lexer, sets new input
      setInput: function setInput(input, yy) {
        this.yy = yy || this.yy || {};
        this._input = input;
        this._more = this._backtrack = this.done = false;
        this.yylineno = this.yyleng = 0;
        this.yytext = this.matched = this.match = '';
        this.conditionStack = ['INITIAL'];
        this.yylloc = {
          first_line: 1,
          first_column: 0,
          last_line: 1,
          last_column: 0
        };
        if (this.options.ranges) {
          this.yylloc.range = [0, 0];
        }
        this.offset = 0;
        return this;
      },
      // consumes and returns one char from the input
      input: function input() {
        var ch = this._input[0];
        this.yytext += ch;
        this.yyleng++;
        this.offset++;
        this.match += ch;
        this.matched += ch;
        var lines = ch.match(/(?:\r\n?|\n).*/g);
        if (lines) {
          this.yylineno++;
          this.yylloc.last_line++;
        } else {
          this.yylloc.last_column++;
        }
        if (this.options.ranges) {
          this.yylloc.range[1]++;
        }
        this._input = this._input.slice(1);
        return ch;
      },
      // unshifts one char (or a string) into the input
      unput: function unput(ch) {
        var len = ch.length;
        var lines = ch.split(/(?:\r\n?|\n)/g);
        this._input = ch + this._input;
        this.yytext = this.yytext.substr(0, this.yytext.length - len);
        //this.yyleng -= len;
        this.offset -= len;
        var oldLines = this.match.split(/(?:\r\n?|\n)/g);
        this.match = this.match.substr(0, this.match.length - 1);
        this.matched = this.matched.substr(0, this.matched.length - 1);
        if (lines.length - 1) {
          this.yylineno -= lines.length - 1;
        }
        var r = this.yylloc.range;
        this.yylloc = {
          first_line: this.yylloc.first_line,
          last_line: this.yylineno + 1,
          first_column: this.yylloc.first_column,
          last_column: lines ? (lines.length === oldLines.length ? this.yylloc.first_column : 0) + oldLines[oldLines.length - lines.length].length - lines[0].length : this.yylloc.first_column - len
        };
        if (this.options.ranges) {
          this.yylloc.range = [r[0], r[0] + this.yyleng - len];
        }
        this.yyleng = this.yytext.length;
        return this;
      },
      // When called from action, caches matched text and appends it on next action
      more: function more() {
        this._more = true;
        return this;
      },
      // When called from action, signals the lexer that this rule fails to match the input, so the next matching rule (regex) should be tested instead.
      reject: function reject() {
        if (this.options.backtrack_lexer) {
          this._backtrack = true;
        } else {
          return this.parseError('Lexical error on line ' + (this.yylineno + 1) + '. You can only invoke reject() in the lexer when the lexer is of the backtracking persuasion (options.backtrack_lexer = true).\n' + this.showPosition(), {
            text: "",
            token: null,
            line: this.yylineno
          });
        }
        return this;
      },
      // retain first n characters of the match
      less: function less(n) {
        this.unput(this.match.slice(n));
      },
      // displays already matched input, i.e. for error messages
      pastInput: function pastInput() {
        var past = this.matched.substr(0, this.matched.length - this.match.length);
        return (past.length > 20 ? '...' : '') + past.substr(-20).replace(/\n/g, "");
      },
      // displays upcoming input, i.e. for error messages
      upcomingInput: function upcomingInput() {
        var next = this.match;
        if (next.length < 20) {
          next += this._input.substr(0, 20 - next.length);
        }
        return (next.substr(0, 20) + (next.length > 20 ? '...' : '')).replace(/\n/g, "");
      },
      // displays the character position where the lexing error occurred, i.e. for error messages
      showPosition: function showPosition() {
        var pre = this.pastInput();
        var c = new Array(pre.length + 1).join("-");
        return pre + this.upcomingInput() + "\n" + c + "^";
      },
      // test the lexed token: return FALSE when not a match, otherwise return token
      test_match: function test_match(match, indexed_rule) {
        var token, lines, backup;
        if (this.options.backtrack_lexer) {
          // save context
          backup = {
            yylineno: this.yylineno,
            yylloc: {
              first_line: this.yylloc.first_line,
              last_line: this.last_line,
              first_column: this.yylloc.first_column,
              last_column: this.yylloc.last_column
            },
            yytext: this.yytext,
            match: this.match,
            matches: this.matches,
            matched: this.matched,
            yyleng: this.yyleng,
            offset: this.offset,
            _more: this._more,
            _input: this._input,
            yy: this.yy,
            conditionStack: this.conditionStack.slice(0),
            done: this.done
          };
          if (this.options.ranges) {
            backup.yylloc.range = this.yylloc.range.slice(0);
          }
        }
        lines = match[0].match(/(?:\r\n?|\n).*/g);
        if (lines) {
          this.yylineno += lines.length;
        }
        this.yylloc = {
          first_line: this.yylloc.last_line,
          last_line: this.yylineno + 1,
          first_column: this.yylloc.last_column,
          last_column: lines ? lines[lines.length - 1].length - lines[lines.length - 1].match(/\r?\n?/)[0].length : this.yylloc.last_column + match[0].length
        };
        this.yytext += match[0];
        this.match += match[0];
        this.matches = match;
        this.yyleng = this.yytext.length;
        if (this.options.ranges) {
          this.yylloc.range = [this.offset, this.offset += this.yyleng];
        }
        this._more = false;
        this._backtrack = false;
        this._input = this._input.slice(match[0].length);
        this.matched += match[0];
        token = this.performAction.call(this, this.yy, this, indexed_rule, this.conditionStack[this.conditionStack.length - 1]);
        if (this.done && this._input) {
          this.done = false;
        }
        if (token) {
          return token;
        } else if (this._backtrack) {
          // recover context
          for (var k in backup) {
            this[k] = backup[k];
          }
          return false; // rule action called reject() implying the next rule should be tested instead.
        }

        return false;
      },
      // return next match in input
      next: function next() {
        if (this.done) {
          return this.EOF;
        }
        if (!this._input) {
          this.done = true;
        }
        var token, match, tempMatch, index;
        if (!this._more) {
          this.yytext = '';
          this.match = '';
        }
        var rules = this._currentRules();
        for (var i = 0; i < rules.length; i++) {
          tempMatch = this._input.match(this.rules[rules[i]]);
          if (tempMatch && (!match || tempMatch[0].length > match[0].length)) {
            match = tempMatch;
            index = i;
            if (this.options.backtrack_lexer) {
              token = this.test_match(tempMatch, rules[i]);
              if (token !== false) {
                return token;
              } else if (this._backtrack) {
                match = false;
                continue; // rule action called reject() implying a rule MISmatch.
              } else {
                // else: this is a lexer rule which consumes input without producing a token (e.g. whitespace)
                return false;
              }
            } else if (!this.options.flex) {
              break;
            }
          }
        }
        if (match) {
          token = this.test_match(match, rules[index]);
          if (token !== false) {
            return token;
          }
          // else: this is a lexer rule which consumes input without producing a token (e.g. whitespace)
          return false;
        }
        if (this._input === "") {
          return this.EOF;
        } else {
          return this.parseError('Lexical error on line ' + (this.yylineno + 1) + '. Unrecognized text.\n' + this.showPosition(), {
            text: "",
            token: null,
            line: this.yylineno
          });
        }
      },
      // return next match that has a token
      lex: function lex() {
        var r = this.next();
        if (r) {
          return r;
        } else {
          return this.lex();
        }
      },
      // activates a new lexer condition state (pushes the new lexer condition state onto the condition stack)
      begin: function begin(condition) {
        this.conditionStack.push(condition);
      },
      // pop the previously active lexer condition state off the condition stack
      popState: function popState() {
        var n = this.conditionStack.length - 1;
        if (n > 0) {
          return this.conditionStack.pop();
        } else {
          return this.conditionStack[0];
        }
      },
      // produce the lexer rule set which is active for the currently active lexer condition state
      _currentRules: function _currentRules() {
        if (this.conditionStack.length && this.conditionStack[this.conditionStack.length - 1]) {
          return this.conditions[this.conditionStack[this.conditionStack.length - 1]].rules;
        } else {
          return this.conditions["INITIAL"].rules;
        }
      },
      // return the currently active lexer condition state; when an index argument is provided it produces the N-th previous condition state, if available
      topState: function topState(n) {
        n = this.conditionStack.length - 1 - Math.abs(n || 0);
        if (n >= 0) {
          return this.conditionStack[n];
        } else {
          return "INITIAL";
        }
      },
      // alias for begin(condition)
      pushState: function pushState(condition) {
        this.begin(condition);
      },
      // return the number of states currently on the stack
      stateStackSize: function stateStackSize() {
        return this.conditionStack.length;
      },
      options: {},
      performAction: function anonymous(yy, yy_, $avoiding_name_collisions, YY_START) {
        var YYSTATE = YY_START;
        switch ($avoiding_name_collisions) {
          case 0:
            return 9;
            break;
          case 1:
            parser.yy.cursorFound = yy_.yylloc;
            return 16;
            break;
          case 2:
            return 15;
            break;
          case 3:
            this.begin('singleQuote');
            return 20;
            break;
          case 4:
            if (parser.handleQuotedValueWithCursor(this, yy_.yytext, yy_.yylloc, '\'')) {
              return 22;
            }
            return 21;
            break;
          case 5:
            this.popState();
            return 20;
            break;
          case 6:
            this.begin('doubleQuote');
            return 20;
            break;
          case 7:
            if (parser.handleQuotedValueWithCursor(this, yy_.yytext, yy_.yylloc, '"')) {
              return 22;
            }
            return 21;
            break;
          case 8:
            this.popState();
            return 20;
            break;
          case 9:
            return 17;
            break;
          case 10:
            return 6;
            break;
        }
      },
      rules: [/^(?:\s+)/, /^(?:\u2020)/, /^(?:[a-zA-Z]+[:])/, /^(?:')/, /^(?:(?:\\[']|[^'])+)/, /^(?:')/, /^(?:")/, /^(?:(?:\\["]|[^"])+)/, /^(?:")/, /^(?:[^"'\s\u2020]+)/, /^(?:$)/],
      conditions: {
        "singleQuote": {
          "rules": [4, 5],
          "inclusive": false
        },
        "doubleQuote": {
          "rules": [7, 8],
          "inclusive": false
        },
        "INITIAL": {
          "rules": [0, 1, 2, 3, 6, 9, 10],
          "inclusive": true
        }
      }
    };
    return lexer;
  }();
  parser.lexer = lexer;
  function Parser() {
    this.yy = {};
  }
  Parser.prototype = parser;
  parser.Parser = Parser;
  return new Parser();
}();
export default globalSearchParser;