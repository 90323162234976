function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { getLastKnownConfig } from "../config/hueConfig";
import { post } from "../api/utils";
// HOW TO TRACK EVENTS
// a) Calling the hueAnalytics.log or hueAnalytics.convert manually
// b) Using the attribute "data-hue-analytics" on a clickable HTML element
// and set a string value containing the area followed by a colon and the action e.g.
// <a href="#" data-hue-analytics="metastore:remove stuff">Remove</a>
//
// Setting the additional attribute data-hue-analytics-prio="true" will cause the interaction
// to be logged in the Hue backend, independent of the collect_usage setting. This should be used
// with caution to prevent flooding the log.
var formatGaData = function formatGaData(area, action) {
  return {
    type: 'event',
    name: area,
    params: {
      action: action,
      version: window.HUE_VERSION || ''
    }
  };
};

// Check and warning for when the analytics log/convert are called incorrectly
// by legacy non typescript code. We want this to fail silenctly in hueDebugAnalytics mode.
var validateParameterTypes = function validateParameterTypes(area, action) {
  var typedWindow = window;
  if (typedWindow.DEV && typedWindow.hueDebugAnalytics) {
    if (typeof area !== 'string') {
      console.error("hueAnalytics parameter \"area\" must be a string");
    }
    if (typeof action !== 'string') {
      console.error("hueAnalytics parameter \"action\" must be a string");
    }
  }
  return typeof area === 'string' && typeof action === 'string';
};
export var hueAnalytics = {
  log: function log(area, action, isPrioritised) {
    var _config$hue_config;
    var config = getLastKnownConfig();
    var typedWindow = window;
    if (isPrioritised) {
      this.convert(area, action);
    }
    if (config !== null && config !== void 0 && (_config$hue_config = config.hue_config) !== null && _config$hue_config !== void 0 && _config$hue_config.collect_usage) {
      if (!validateParameterTypes(area, action)) {
        return;
      }
      var _formatGaData = formatGaData(area, action),
        type = _formatGaData.type,
        name = _formatGaData.name,
        params = _formatGaData.params;

      // Quick debug mode to check that the analytics logging is working when developing new features
      // Turn on by typing "window.hueDebugAnalytics = true;" in the browser JS console or setting
      // "window.hueDebugAnalytics = true" in hue.js;
      if (typedWindow.DEV && typedWindow.hueDebugAnalytics) {
        console.info('Analytics debug:', type, name, params);
      }
      if (!typedWindow.DEV) {
        var _typedWindow$gtag;
        (_typedWindow$gtag = typedWindow.gtag) === null || _typedWindow$gtag === void 0 ? void 0 : _typedWindow$gtag.call(typedWindow, type, name, params);
      }
    }
  },
  convert: function convert(area, action) {
    if (!validateParameterTypes(area, action)) {
      return;
    }
    post('/desktop/log_analytics', {
      area: area,
      action: action
    });
  }
};
export var setupGlobalListenersForAnalytics = function setupGlobalListenersForAnalytics() {
  document.addEventListener('click', function (event) {
    var _eventTarget$dataset;
    var eventTarget = event === null || event === void 0 ? void 0 : event.target;
    var analyticsDataAttribute = (_eventTarget$dataset = eventTarget.dataset) === null || _eventTarget$dataset === void 0 ? void 0 : _eventTarget$dataset.hueAnalytics;
    if (analyticsDataAttribute) {
      var _analyticsDataAttribu = analyticsDataAttribute.split(':'),
        _analyticsDataAttribu2 = _slicedToArray(_analyticsDataAttribu, 2),
        area = _analyticsDataAttribu2[0],
        action = _analyticsDataAttribu2[1];
      var isPrioritised = !!eventTarget.dataset.hueAnalyticsPrio;
      return hueAnalytics.log(area, action, isPrioritised);
    }
  });
};
export default hueAnalytics;